import { Typography, Box, Spacer as AlliumSpacer, Card, Icon, StackView } from '@telus-uds/components-web'

export const ErrorCard = (icon, title, description) => (
  <Card>
    <StackView
      direction="row"
      space={4}
      tokens={{
        alignItems: 'center',
      }}
    >
      <Icon icon={icon} variant={{ size: 'extraLarge', color: 'brand' }} />
      <Box flex={1}>
        <Typography variant={{ size: 'h2' }}>{title}</Typography>
        <AlliumSpacer space={3} />
        <Typography variant={{ color: 'secondary' }}>{description}</Typography>
      </Box>
    </StackView>
  </Card>
)
