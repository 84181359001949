import { ArticleProps } from './ArticleFilter'

export enum ACTION_TYPES {
  GET_PAGES_SUCCEEDED = 'GET_PAGES_SUCCEEDED',
  NO_PAGES_FOUND = 'NO_PAGES_FOUND',
  GET_PAGES_ERROR = 'GET_PAGES_ERROR',
  LOAD_MORE_RESULTS = 'LOAD_MORE_RESULTS',
  DISPLAY_CARDS_FULL_WIDTH = 'DISPLAY_CARDS_FULL_WIDTH',
  TAG_FILTER_CHANGED = 'TAG_FILTER_CHANGED',
  YEAR_FILTER_CHANGED = 'YEAR_FILTER_CHANGED',
}

export type articleFilterState = {
  isLoading: boolean
  activeTags: string[]
  activeYear: string
  yearFilter: string[]
  allArticles: ArticleProps[]
  filteredArticles: ArticleProps[]
  displayCount: number
  showCardsFullWidth: boolean
  error: boolean
  enableLoadMore: boolean
}

type Keys = keyof articleFilterState
type Payload = articleFilterState[Keys]

type action = {
  type: ACTION_TYPES
  payload?: Payload
}

export const SHOW_BY = 12
export const ALL_ID = 'all'

const enableMore = (articlesLength, displayCount) => (displayCount >= articlesLength ? false : true)

const articleWithYear = (article: ArticleProps, year: string) => article.publishDate.getFullYear().toString() === year

const articleHasTag = (article: ArticleProps, tag: string) => article.tags.includes(tag)

export const filterArticles = (
  articles: articleFilterState['allArticles'],
  tags: articleFilterState['activeTags'],
  year: articleFilterState['activeYear']
) => {
  // 0 && 1
  if (year !== ALL_ID && tags.includes(ALL_ID)) return articles.filter((a) => articleWithYear(a, year))
  // 1 && 0
  else if (year === ALL_ID && !tags.includes(ALL_ID))
    return articles.filter((article) => tags.some((selectedTag) => articleHasTag(article, selectedTag)))
  // 0 & 0
  else if (year !== ALL_ID && !tags.includes(ALL_ID))
    return articles.filter((article) =>
      tags.some((selectedTag) => articleHasTag(article, selectedTag) && articleWithYear(article, year))
    )

  // 1 && 1
  return articles
}

export const reducer = (state: articleFilterState, action: action): articleFilterState => {
  switch (action.type) {
    case ACTION_TYPES.DISPLAY_CARDS_FULL_WIDTH:
      return {
        ...state,
        showCardsFullWidth: action.payload as articleFilterState['showCardsFullWidth'],
      }

    case ACTION_TYPES.TAG_FILTER_CHANGED: {
      const selectedTags = action.payload as articleFilterState['activeTags']
      let activeTags: string[]

      if (selectedTags.length === 0 || selectedTags.at(-1) === ALL_ID) activeTags = [ALL_ID]
      else activeTags = selectedTags.filter((t) => t !== ALL_ID)

      const filteredArticles = filterArticles(state.allArticles, activeTags, state.activeYear)

      return {
        ...state,
        activeTags,
        filteredArticles,
        enableLoadMore: enableMore(filteredArticles.length, state.displayCount),
      }
    }

    case ACTION_TYPES.YEAR_FILTER_CHANGED: {
      const activeYear = action.payload as articleFilterState['activeYear']

      const filteredArticles = filterArticles(state.allArticles, state.activeTags, activeYear)

      return {
        ...state,
        activeYear,
        filteredArticles,
        enableLoadMore: enableMore(filteredArticles.length, state.displayCount),
      }
    }

    case ACTION_TYPES.NO_PAGES_FOUND:
      return {
        ...state,
        isLoading: false,
        error: false,
        enableLoadMore: false,
      }

    case ACTION_TYPES.GET_PAGES_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
        enableLoadMore: false,
      }

    case ACTION_TYPES.GET_PAGES_SUCCEEDED:
      const articlesFound = action.payload as articleFilterState['allArticles']
      const yearFilter = articlesFound
        .sort((a, b) => b.publishDate.getTime() - a.publishDate.getTime())
        .reduce((acc, curr) => {
          const year = curr.publishDate.getFullYear().toString()
          return !acc.includes(year) ? [...acc, year] : acc
        }, [])

      return {
        ...state,
        allArticles: articlesFound,
        yearFilter,
        filteredArticles: articlesFound,
        isLoading: false,
        error: false,
        enableLoadMore: enableMore(articlesFound.length, state.displayCount),
      }

    case ACTION_TYPES.LOAD_MORE_RESULTS:
      const articlesLength = state.filteredArticles.length
      const newCount = state.displayCount + SHOW_BY

      return {
        ...state,
        displayCount: newCount >= articlesLength ? articlesLength : newCount,
        enableLoadMore: enableMore(articlesLength, newCount),
      }
    default:
      throw Error('unknown action')
  }
}
