import alloyAnalytics from '@telus/alloy-analytics'
import { FilterProps, TagProps } from '../../blocks/core/ArticleFilter/ArticleFilter'

type SysID = FilterProps['sysID']
type EntryTitle = FilterProps['entryTitle']
type Tag = TagProps['label']

type FilterCategoryProps = { sysID: SysID; entryTitle: EntryTitle; tag: Tag; isNewFilter: boolean }
type FilterYearProps = { sysID: SysID; entryTitle: EntryTitle; year: number }

const TOOL_TYPE = 'sort_and_filter'

const alloyEvents = {
  filterCategory: ({ sysID, entryTitle, tag, isNewFilter }: FilterCategoryProps) => {
    const action = isNewFilter ? 'add_filter' : 'remove_filter'
    const toolSubmit = {
      name: `${action}-category-${tag}`,
      toolName: `sort_and_filter-${entryTitle}`,
      toolType: TOOL_TYPE,
      toolUsageID: sysID,
      toolSelections: `${action}-category-${tag}`,
    }
    alloyAnalytics.event('toolSubmit').webInteraction(toolSubmit).fire()
  },
  filterYear: ({ sysID, entryTitle, year }: FilterYearProps) => {
    const toolSubmit = {
      name: `filter-year-${year}`,
      toolName: `sort_and_filter-${entryTitle}`,
      toolType: TOOL_TYPE,
      toolUsageID: sysID,
      toolSelections: `filter-year-${year}`,
    }
    alloyAnalytics.event('toolSubmit').webInteraction(toolSubmit).fire()
  },
}

export default alloyEvents
