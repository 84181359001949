// TODO: add to docs how to properly implement internationalization
const ARTICLE_FILTER_I18N = {
  en: {
    categoryFilterLabel: 'Filter by category',
    yearFilterLabel: 'Filter by year',
    resultsLabel: (filtered, all) => `Showing ${filtered} of ${all} articles`,
    loadMoreLabel: 'Show more articles',
    layoutLabel: {
      list: 'List',
      grid: 'Grid',
    },
    noFoundTitleLabel: "We couldn't find any articles for your search",
    noFoundLabel: 'Try modifying the applied filters.',
    sorryLabel: 'Sorry',
    errorLabel: 'Something went wrong.',
    loadingLabel: 'Loading',
    allLabel: 'All',
  },
  fr: {
    categoryFilterLabel: 'Filtrer par catégorie',
    yearFilterLabel: 'Filtrer par année',
    resultsLabel: (filtered, all) => `${filtered} de ${all} articles affichés`,
    loadMoreLabel: "Afficher plus d'articles",
    layoutLabel: {
      list: 'List',
      grid: 'Grille',
    },
    noFoundTitleLabel: "Nous n'avons pas trouvé d'articles correspondant à votre recherche",
    noFoundLabel: 'Aucun article trouvé.',
    sorryLabel: 'Désolé',
    errorLabel: "Quelque chose n'a pas marché.",
    loadingLabel: 'Chargement',
    allLabel: 'Tout afficher',
  },
}

export default ARTICLE_FILTER_I18N
