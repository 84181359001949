import { withContentful } from 'src/siteBuilder/renderer/contentful/withContentful'

import { IMediaReleaseArticleFilterFields } from 'contentful-types'

import ArticleFilter, { FilterProps } from './ArticleFilter'
import { mapTags } from 'src/siteBuilder/renderer/contentful/mapTags'

const ContentfulPageFilter = withContentful<IMediaReleaseArticleFilterFields, FilterProps>(ArticleFilter, {
  sysID: (props) => props.sys.id,
  filterTags: (props) => mapTags(props.fields.filterTags),
  baseTags: (props) => mapTags(props.fields.baseTags),
  customLoadMoreLabel: (props) => props.fields.customLoadMoreLabel,
})

export default ContentfulPageFilter
