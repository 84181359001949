const DefaultOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

/**
 * returns a string with a localized representation of the date based on the options passed
 * @param  {string|number|Date} date - Date to format
 * @param  {string} [locale] - Localization. Default 'en'
 * @param  {Intl.DateTimeFormatOptions} [options] - Required format. Default DefaultOptions object
 *
 * @returns {string} Localized Date
 *
 * @example
 * localizeDateFormat('2022-03-11T00:00-05:00')
 * // returns March 10, 2022
 * @example
 * localizeDateFormat('2022-07-25', 'fr-CA')
 * // returns 24 juillet 2022
 *
 */
export const localizeDateFormat = (
  date: string | number | Date,
  locale: Intl.LocalesArgument = 'en',
  options: Intl.DateTimeFormatOptions = DefaultOptions
): string => {
  const parsedDate = new Date(date)
  return parsedDate.toLocaleDateString(locale, options)
}
