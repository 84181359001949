const pathHasQueryParams = (path: string) => {
  return path.includes('?')
}

export const generateUrlWithQueryParams = (path: string, params: Record<string, string | string[]>) => {
  if (!params) {
    return path
  }

  const queryString = Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== null)
    .map((key) => `${key}=${params[key]}`)
    .join('&')

  // if query string is empty, simply return the path
  if (!queryString) {
    return path
  }

  if (pathHasQueryParams(path)) {
    return `${path}&${queryString}`
  }
  return `${path}?${queryString}`
}
