import { useContext, useEffect, useReducer } from 'react'

import {
  Tags as AlliumTags,
  Button,
  FlexGrid,
  Typography,
  Spacer as AlliumSpacer,
  Spinner,
  ButtonGroup,
  Select,
  InputLabel,
  StoryCard as StoryCardAllium,
} from '@telus-uds/components-web'

import Grid from '@telus-uds/palette-allium/build/web/icons/Grid'
import List from '@telus-uds/palette-allium/build/web/icons/List'
import Search from '@telus-uds/palette-allium/build/web/icons/Search'
import Close from '@telus-uds/palette-allium/build/web/icons/Close'

import { SpacerProps } from '../Spacer/Spacer'
import alloyEvents from '../../../utils/alloyAnalytics/sortAndFilter'
import { SiteBuilderContext } from 'src/siteBuilder/renderer/context/SiteBuilderContext'

import ARTICLE_FILTER_I18N from './ArticleFilter.i18n'
import { ACTION_TYPES, ALL_ID, articleFilterState, reducer, SHOW_BY } from './ArticleFilterReducer'
import { localizeDateFormat } from 'src/utils/localizeDateFormat'
import { mapDataAttrsToDataSet } from 'src/siteBuilder/utils/dataAttributes'
import { generateUrlWithQueryParams } from 'src/siteBuilder/utils/generateUrlWithQueryParams'

import getConfig from 'next/config'
import { BlockInvalid } from '@/siteBuilder/components/errors/BlockInvalid'
import { ErrorCard } from './ErrorCard'

const {
  publicRuntimeConfig: { API_BASE_PATH },
} = getConfig()

export type TagProps = {
  id: string
  label: string
}

export type FilterProps = {
  sysID: string
  entryTitle: string
  spacer?: SpacerProps
  baseTags: TagProps[]
  filterTags: TagProps[]
  rootSlug?: string
  customLoadMoreLabel?: string
  // initialValues?: string[]
  // maxValues?: number
  // tokens?: object // ? customize Allium Tags
}

export type ArticleProps = {
  id: string
  title: string
  description: string
  publishDate: Date
  url: string
  tags: string[]
}

const initialState: articleFilterState = {
  isLoading: true,
  activeTags: [ALL_ID],
  activeYear: ALL_ID,
  yearFilter: [],
  allArticles: [],
  filteredArticles: [],
  displayCount: SHOW_BY,
  showCardsFullWidth: false,
  error: false,
  enableLoadMore: false,
}

const ArticleFilter = ({
  sysID,
  entryTitle,
  baseTags,
  filterTags,
  rootSlug = '',
  customLoadMoreLabel,
  ...rest
}: FilterProps) => {
  const {
    locale: { language },
  } = useContext(SiteBuilderContext)

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    const tagsId = baseTags.map((tag) => tag.id)

    fetch(generateUrlWithQueryParams(`${API_BASE_PATH}api/articles`, { rootSlug, tagsId, language }))
      .then((res) => {
        if (res.ok) return res.json() as Promise<ArticleProps[]>

        throw new Error('Something went wrong.')
      })
      .then((articles) => {
        if (articles.length === 0) dispatch({ type: ACTION_TYPES.NO_PAGES_FOUND })
        else
          dispatch({
            type: ACTION_TYPES.GET_PAGES_SUCCEEDED,
            payload: articles.map((a) => ({ ...a, publishDate: new Date(a.publishDate) })),
          })
      })
      .catch(() => dispatch({ type: ACTION_TYPES.GET_PAGES_ERROR }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadMoreLabel = customLoadMoreLabel || ARTICLE_FILTER_I18N[language].loadMoreLabel

  const {
    categoryFilterLabel,
    yearFilterLabel,
    resultsLabel,
    layoutLabel,
    errorLabel,
    noFoundTitleLabel,
    noFoundLabel,
    sorryLabel,
    loadingLabel,
    allLabel,
  } = ARTICLE_FILTER_I18N[language]

  const findTagSelectedFromFilter = (selectedTags: string[], activeTags: string[]) => {
    const allTags = selectedTags.concat(activeTags)
    const clickedTag = allTags.find((searchTag) => allTags.filter((tag) => tag === searchTag).length === 1)
    return filterTags.find(({ id }) => id === clickedTag)?.label || allLabel
  }

  const filterArticlesByTag = (selectedTags: string[]) => {
    alloyEvents.filterCategory({
      sysID,
      entryTitle,
      tag: findTagSelectedFromFilter(selectedTags, state.activeTags),
      isNewFilter: selectedTags.length > state.activeTags.length,
    })
    dispatch({ type: ACTION_TYPES.TAG_FILTER_CHANGED, payload: selectedTags })
  }

  const filterArticlesByYear = (year: number) => {
    alloyEvents.filterYear({ sysID, entryTitle, year })
    dispatch({ type: ACTION_TYPES.YEAR_FILTER_CHANGED, payload: year })
  }

  const onToggleChangeHandler = (selected: 'grid' | 'list'[]) => {
    if (selected.length === 0) return
    dispatch({ type: ACTION_TYPES.DISPLAY_CARDS_FULL_WIDTH, payload: selected.includes('list') })
  }

  const onPressMore = () => dispatch({ type: ACTION_TYPES.LOAD_MORE_RESULTS })

  const { yearFilter, filteredArticles, isLoading, showCardsFullWidth, displayCount, error, enableLoadMore } = state

  const filteredArticlesLength = filteredArticles.length

  const cardWidth = !showCardsFullWidth && { md: 6, lg: 4 }

  let content

  if (!isLoading && error) 
    content = <FlexGrid.Col>{ErrorCard(Close, sorryLabel, errorLabel)}</FlexGrid.Col> // prettier-ignore

  if (!isLoading && !error && filteredArticlesLength === 0)
    content = <FlexGrid.Col>{ErrorCard(Search, noFoundTitleLabel, noFoundLabel)}</FlexGrid.Col>

  if (!isLoading && !error && filteredArticlesLength)
    content = filteredArticles.slice(0, displayCount).map((article) => (
      <FlexGrid.Col sm={12} {...cardWidth} key={`col-${article.id}`} flex testID={`col-${article.id}`}>
        <StoryCardAllium
          key={article.id}
          href={article.url}
          date={localizeDateFormat(article.publishDate, language)}
          title={article.title}
          description={article.description}
        />
        <AlliumSpacer space={4} key={`spacer-${article.id}`} />
      </FlexGrid.Col>
    ))

  return (
    <div data-testid="article-filter-component">
      <Spinner label={loadingLabel} show={isLoading}>
        <FlexGrid dataSet={mapDataAttrsToDataSet(rest)}>
          <FlexGrid.Row>
            <FlexGrid.Col xs={12}>
              <InputLabel label={categoryFilterLabel} copy={language} />
            </FlexGrid.Col>
          </FlexGrid.Row>
          <AlliumSpacer space={1} />
          <FlexGrid.Row>
            <FlexGrid.Col xs={12}>
              <AlliumTags
                values={state.activeTags}
                items={[{ id: ALL_ID, label: allLabel }, ...filterTags]}
                onChange={filterArticlesByTag}
              />
            </FlexGrid.Col>
          </FlexGrid.Row>

          <AlliumSpacer space={6} />

          <FlexGrid.Row>
            <FlexGrid.Col xs={12} md={3}>
              <Select label={yearFilterLabel} onChange={filterArticlesByYear} initialValue={ALL_ID}>
                <Select.Item key={ALL_ID} value={ALL_ID}>
                  {allLabel}
                </Select.Item>
                {yearFilter.map(
                  (y) => <Select.Item key={y} value={y}>{y}</Select.Item> // prettier-ignore
                )}
              </Select>
            </FlexGrid.Col>
          </FlexGrid.Row>

          <AlliumSpacer space={6} />

          <FlexGrid.Row distribute="between" verticalAlign="bottom">
            <FlexGrid.Col sm={5}>
              <Typography variant={{ colour: 'secondary' }}>
                {resultsLabel(filteredArticlesLength, state.allArticles.length)}
              </Typography>
            </FlexGrid.Col>
            <FlexGrid.Col xs={0} md={4}>
              <ButtonGroup
                iconPosition="left"
                items={[
                  {
                    label: layoutLabel.grid,
                    id: 'grid',
                    icon: Grid,
                  },
                  {
                    label: layoutLabel.list,
                    id: 'list',
                    icon: List,
                  },
                ]}
                values={[showCardsFullWidth ? 'list' : 'grid']}
                onChange={onToggleChangeHandler}
                tokens={{
                  alignItems: 'right',
                  justifyContent: 'right',
                }}
              />
            </FlexGrid.Col>
          </FlexGrid.Row>

          <AlliumSpacer space={5} />

          {!!content && <FlexGrid.Row>{content}</FlexGrid.Row>}

          {!error && enableLoadMore && (
            <>
              <AlliumSpacer space={8} />

              <FlexGrid.Row>
                <FlexGrid.Col horizontalAlign="right">
                  <Button variant={{ priority: 'high' }} onPress={onPressMore}>
                    {loadMoreLabel}
                  </Button>
                </FlexGrid.Col>
              </FlexGrid.Row>
            </>
          )}
        </FlexGrid>
      </Spinner>
    </div>
  )
}

const ArticleFilterWithEarlyReturn = (props: FilterProps) => {
  if (!props.baseTags || props.baseTags.length === 0 || !props.filterTags || props.filterTags.length === 0)
    return <BlockInvalid name="mediaReleaseArticleFilter" description="Tags should be defined" />

  const isRepeated = Object.values(
    props.filterTags.reduce(
      (a, e) => {
        a[e.label] = ++a[e.label] || 0
        return a
      },
      { [ARTICLE_FILTER_I18N.en.allLabel]: 0, [ARTICLE_FILTER_I18N.fr.allLabel]: 0 }
    )
  ).some((n) => n > 0)

  if (isRepeated) return <BlockInvalid name="mediaReleaseArticleFilter" description="Tags should have unique label" />

  return <ArticleFilter {...props} />
}

export default ArticleFilterWithEarlyReturn
